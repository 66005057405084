html {
	height: 100%;
}

body {
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	font-family: 'Poppins', sans-serif;
	padding: 5px 25px 115px;
	position: relative;
	min-height: 100%;
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
}

.splashScreen {
	position: fixed;
	inset: 0px;
	height: 100vh;
	width: 100vw;
	background-color: #000000;
	z-index: 1000;
}

.splashScreen .contentSection {
	position: relative;
	width: 100%;
	height: 100%;
}

.splashScreen .imgBlock {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
}

.splashScreen .textBlock {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	color: #ffffff;
	text-align: center;
}

.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mb-3 {
	margin-bottom: 50px;
}

header {
	position: relative;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	margin-bottom: 5px;
}

.back-btn {
	position: absolute;
	left: 0;
	top: 11px;
}

.menu-btn {
	position: absolute;
	right: 0;
	top: 13px;
}

header span {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #14142b;
}

footer {
	height: 67px;
	background: #ffffff;
	box-shadow: 0 0 200px rgba(0, 17, 51, 0.35);
	border-radius: 29px;
	position: fixed;
	z-index: 250;
	left: 25px;
	right: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	bottom: 2.5%;
}

footer a {
	width: 39px;
	border-radius: 50%;
	height: 39px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

footer a.active {
	background: #5f2eea;
	box-shadow: 0 4px 4px rgba(95, 46, 234, 0.15);
}

footer a.active img {
	filter: brightness(1000);
}

.main-banner {
	width: 100%;
	height: 158px;
	background-size: cover;
	border-radius: 15px;
	background: url('../src/assets/images/home-banner.jpg') no-repeat;
	background-size: 500px;
	padding: 20px;
}

.main-banner h2 {
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 0.75px;
	color: #ffffff;
	margin-bottom: 0;
}

.main-banner h6 {
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.75px;
	color: #fefefe;
	margin-top: 0;
	margin-bottom: 20px;
}

.main-banner a {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.75px;
	color: #ffffff;
	height: 40px;
	width: 179px;
	border: 2px solid #ffffff;
	box-sizing: border-box;
	border-radius: 40px;
}

.main-banner a:hover,
.main-banner a:focus {
	background-color: #e8e1e1;
	color: #5f2eea;
	text-decoration: none;
}

.provide-state {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #6e7191;
	margin: 10px 0 20px;
}

.general-head {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.75px;
	color: #14142b;
	margin: 10px 0px;
}

.card-body {
	padding: 0 20px 20px 25px;
}

.accordion > .card {
	background: #eff0f7;
	border-radius: 16px;
	padding: 0;
}

.accordion > .card > .card-header {
	background: #eff0f7;
	border-radius: 16px;
	padding: 0;
	border: none !important;
}

.accordion > .card > .card-header div.btn.btn-link.btn-block.text-left {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.75px;
	color: #4e4b66;
	padding: 15px 25px;
	position: relative;
}

.accordion > .card > .card-header button.btn.btn-link.btn-block.text-left {
	content: '';
	position: absolute;
	right: 18px;
	top: 14px;
	bottom: 14px;
	width: 24px;
	height: 24px;
	/*background: url("../src/assets/images/plus.svg");*/
}

.accordion-sign {
	margin-left: 89px;
}

.operating-hours {
	height: 150px;
	background: #f7f7fc;
	border-radius: 15px;
	padding: 21px 18px 21px 21px;
	border: 1px solid #6e71919e;
	margin: 30px 0;
	margin-top: 0px;
}

.operating-hours .general-head img {
	vertical-align: top;
}

.operating-hours .general-head {
	margin-top: 0;
}

.operating-hours .times {
	margin: 13px 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	height: 22px;
	border-bottom: 1px solid #6e7191;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	width: 100%;
	color: #6e7191;
}

.team-member {
	background: #ffffff;
	height: 166px;
	border-radius: 24px;
	width: 100%;
	position: relative;
	z-index: 111;
	overflow: hidden;
	padding: 27px 27px 27px calc(39% + 7px);
	margin-bottom: 16px;
}

.team-member img {
	width: 33%;
	position: absolute;
	height: 100%;
	object-fit: cover;
	left: 0;
	bottom: 0;
}

.team-member p {
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #848b95;
	margin: 0 0;
}

.team-member a {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	font-family: 'Poppins', sans-serif;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: 0.75px;
	color: #5f2eea;
	border: 2px solid #5f2eea;
	box-sizing: border-box;
	border-radius: 40px;
	height: 40px;
	width: 179px;
	margin: 23px auto 0;
}

.btn-text {
	color: #5f2eea;
}

.team-member a:focus {
	background-color: #5f2eea;
	color: white;
	text-decoration: none;
}

.appoint-form {
	width: 100%;
}

.appoint-form label {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.75px;
	color: #14142b;
	margin: 0 0;
}

.date-btn {
	background: #eff0f7;
	border-radius: 15px;
	height: 56px;
	display: block;
	border: 0;
	width: 100%;
	text-align: left;
	outline: none;
	padding: 16px 24px;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.75px;
	color: #4e4b66;
	position: relative;
	margin-bottom: 20px;
}

th.prev,
th.next {
	opacity: 0.4;
	font-size: 17px !important;
}

.date-btn:focus {
	outline: none;
	border: 0;
}

.date-btn img {
	position: absolute;
	right: 27px;
	top: 22px;
}

.modal-dialog {
	height: 100vh;
	overflow: hidden;
	max-width: 1170px;
	margin: 0 auto;
}

.modal-content {
	height: 100%;
	border: 0;
	border-radius: 0;
	overflow: hidden;
}

.modal-body {
	position: relative;
	padding: 5px 25px 112px;
	height: 100%;
	overflow: auto;
}

.modal {
	background: white;
}

.confirm-btn.hiddeen {
	display: none;
}

.confirm-btn {
	width: 100%;
	position: fixed;
	background: white;
	bottom: 0;
	height: 112px;
	left: 0;
	right: 0;
	padding: 20px 25px 5px;
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
}

.confirm-btn button {
	background: #5f2eea;
	border-radius: 40px;
	border: none;
	outline: none;
	height: 56px;
	width: 100%;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.75px;
	color: #f7f7fc;
	justify-content: center;
}

.select_time {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	width: 100%;
	height: 50px;
	margin-bottom: 20px;
}

.select_time label {
	position: static;
	width: 150px;
	border: 1px solid #d9dbe9;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 20px;
	height: 50px;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.75px;
	color: #4e4b66;
}

.select_time label.active {
	border: 2px solid #5f2eea;
	background: #efeafd;
}

.select_time label input {
	display: none;
}

.service-div {
	background: #f7f7fc;
	border-radius: 12px;
	padding: 12px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
	cursor: pointer;
}

.service-div:hover,
.service-div.active {
	background: #ffffff;
	box-shadow: 0px 7.53083px 15.0617px rgba(17, 17, 17, 0.08);
	border-radius: 12px;
}

.service-div h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0.75px;
	color: #14142a;
	margin: 0 0;
}

.service-div p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #4e4b66;
	margin: 0 0;
}

.service-data {
	width: calc(100% - 65px);
	float: left;
	padding-right: 10px;
}

.serice-img {
	width: 60px;
	height: 60px;
	float: left;
	background: #b5b9c3;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.service-div:hover .serice-img,
.service-div.active .serice-img {
	background: #5f2eea;
}

.appoint-form textarea {
	background: #eff0f7;
	border-radius: 16px;
	width: 100%;
	height: 128px;
	border: 0;
	padding: 20px 25px;
	outline: none;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.75px;
	color: #a0a3bd;
}

.hint {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #4e4b66;
}

.confirm-btn-form button {
	background: #5f2eea;
	border-radius: 40px;
	border: none;
	outline: none;
	height: 56px;
	width: 100%;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.75px;
	color: #f7f7fc;
	justify-content: center;
	margin-top: 45px;
}

.clinic-ban {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	top: 50px;
	border-radius: 24px 24px 0px 0px;
	margin-top: 10px;
}

.dr-name {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 1px;
	color: #14142b;
	padding-top: 25px;
}

.clinic-main {
	padding-top: 75%;
	border-radius: 24px 25px 0px 0px;
}

.rating i {
	color: #ffd211;
}

.rating {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #8e9aab;
}

.clinic-address {
	background: #ffffff;
	border: 1px solid #f2f4f5;
	box-sizing: border-box;
	border-radius: 12px;
	padding: 25px;
	margin: 7px 0 5px;
}

.clinic-address a {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.75px;
	color: #5f2eea;
	border: 2px solid #5f2eea;
	box-sizing: border-box;
	border-radius: 40px;
	width: 279px;
	height: 40px;
	margin-left: 17px;
}

.clinic-address h5 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #14142b;
}

.clinic-address p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #4e4b66;
	margin: 10px 0px;
}

.clinic-address ul {
	padding-left: 25px;
	margin-top: -10px;
	margin-bottom: 0;
}

.clinic-address ul li {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.75px;
	color: #4e4b66;
}

.clinic-address h5 img {
	margin-right: 5px;
	vertical-align: text-top;
}

.nav-tabs {
	border-bottom: 1px solid #dedede;
	height: 48px;
	list-style: none;
	align-items: center;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #5f2eea;
}

.nav-tabs .nav-link .active {
	color: #5f2eea;
	position: absolute;
	content: '';
	width: 32px;
	height: 2px;
	border-radius: 99px 99px 0 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 41px;
}

.nav-tabs .nav-link {
	border: 0;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 22px;
	color: #b2bac6;
	padding-left: 0;
	padding-right: 0;
	margin-right: 32px;
}

.tab-pane p {
	font-style: normal;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;

	color: #8e9aab;

	margin: 10px 0px;
}

.review-style {
	margin: 10px 0px;
	background: #ffffff;
	box-shadow: 0px 7.53083px 15.0617px rgba(17, 17, 17, 0.08);
	border-radius: 12px;
	padding: 20px 15px;
	margin-bottom: 25px;
}

.user-ico {
	width: 60px;
	height: 60px;
	display: flex;
	background: rgb(91 45 227);
	font-size: 30px;
	color: #ffffff;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	float: left;
}

.user-rating {
	width: calc(100% - 60px);
	padding-left: 15px;
	float: left;
}

.user-rating .rating {
	line-height: 17px;
}

.reviewer-name {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 1px;
	color: #14142b;
	margin: 0px 0px;
}

.user-rating p {
	margin: 5px 0px;
	font-size: 14px;
	line-height: 21px;
}

.tree-img {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 0;
	z-index: 4;
	width: 144.28px;
	top: 261px;
}

.text-img {
	position: absolute;
	width: 198.78px;
	left: 0;
	right: 0;
	bottom: 68px;
	z-index: 3;
	margin: 0 auto;
}

.first-div {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	background: linear-gradient(40.99deg, #14142a 0%, #4e4b66 131.48%);
}

.confirmation-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	position: absolute;
	width: 323px;
	height: 82px;
	left: 45px;
	top: 407px;
	bottom: 323px;
}

.confirmation-text h4 {
	width: 225px;
	height: 28px;
	left: 52.5px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #14142b;
	margin: 10px 0px;
}

.confirmation-text p {
	width: 250px;
	height: 44px;
	top: 38px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #6e7191;
	margin: 10px 0px;
}

.btn-confirm {
	position: absolute;
	left: 50px;
	right: 6.67%;
	top: 87.81%;
	bottom: 5.3%;
	background: #5f2eea;
	border-radius: 40px;
	border: none;
	outline: none;
	height: 56px;
	width: 325px;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.75px;
	color: #f7f7fc;
	justify-content: center;
}

.confirmation-text img {
	position: absolute;
	width: 200px;
	height: 200px;
	left: 55px;
	bottom: 100px;
}

.popup-notify {
	z-index: auto;
	position: absolute;
	overflow: hidden;
	width: 415px;
	height: 304px;
	left: 0px;
	bottom: 0px;
	background: #eff0f7;
	border-radius: 24px 24px 0px 0px;
}

.modal {
	background: none !important;
}

.popup-notify .modal-body {
	overflow: hidden;
	background: #ffffff;
	display: block;
}

.popup-notify .dock-notify {
	position: absolute;
	margin-top: 11px;
	margin-right: 164px;
	margin-left: 164px;
	background: #d6d8e7;
	border-radius: 8px;
}

.tutorial-chrome {
	position: absolute;
	width: 280.25px;
	height: 71px;
	margin-left: 51px;
	margin-right: 43.75px;
	margin-top: 60px;
	background: url(./assets/images/wcd.svg);
	border-radius: 12px;
}

.tutorial-safari {
	position: absolute;
	width: 280.25px;
	height: 71px;
	margin-left: 26px;
	margin-right: 43.75px;
	margin-top: 60px;
	background: url(./assets/images/safari.svg);
	border-radius: 12px;
}

.popup-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #14142b;
	margin-top: 172px;
	margin-left: 72.5px;
	margin-right: 72.5px;
	width: 230px;
	height: 28px;
}

.popup-text-1 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #6e7191;
	margin: 10px 139px;
	width: 97px;
	height: 22px;
}

.popup-text-1 img {
	width: 15px;
	height: 16px;
	top: 3px;
}

.popup-text-2 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.75px;
	color: #6e7191;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 5px;
}

.popup-text-2 strong {
	color: #14142b;
}

.popup-text-2 img {
	margin-left: 5px;
}

.close-notify {
	position: absolute;
	margin-top: 20px;
	margin-left: 345px;
}

@media (min-width: 428px) {
	.popup-notify {
		width: 428px;
	}
}

@media (min-width: 425px) {
	.team-member img {
		width: 110px;
	}

	.team-member {
		padding-left: calc(127px + 7px);
	}

	.select_time {
		justify-content: flex-start;
	}

	.select_time label {
		margin-right: 20px;
	}
}

@media (min-width: 414px) {
	.main-banner {
		background-size: 400px;
	}

	.accordion-sign {
		margin-left: 100px;
	}

	.accordion-sign-inactive {
		margin-left: 120px;
	}
}

@media (max-width: 394px) {
	.main-banner {
		background-size: 340px;
	}

	.accordion-sign {
		margin-left: 80px;
	}

	.accordion-sign-inactive {
		margin-left: 100px;
	}
}
@media (max-width: 376px) {
	.main-banner {
		background-size: 340px;
	}

	.accordion-sign {
		margin-left: 60px;
	}

	.accordion-sign-inactive {
		margin-left: 88px;
	}

	.confirmation-text {
		left: 30px;
	}

	.btn-confirm {
		left: 30px;
	}

	.clinic-address a {
		margin-left: 0px;
	}

	.popup-notify {
		width: 377px;
	}

	.close-notify {
		margin-left: 310px;
	}

	.tutorial-ss {
		margin-left: 35px;
	}
}

@media (max-width: 352px) {
	.team-member {
		background: #ffffff;
		height: 180px;
	}

	.team-member a {
		width: 100%;
		font-size: 11px;
	}

	.accordion-sign {
		margin-left: 30px;
	}
}

@media (max-width: 345px) {
	.operating-hours .times {
		font-size: 11px;
	}
}
